<template>
  <div id="layout-member" class="bg-white sm:bg-gray-20 pt-16">
    <navbar class="fixed top-0 w-full z-navbar" />
    <div id="personal-info-container" class="relative">
      <line-banner-with-client />

      <div class="container mx-auto flex gap-x-6 sm:px-4 sm:py-10">
        <aside v-if="isSideBarShow" class="w-1/3 xl:w-1/4">
          <member-route-list />
        </aside>
        <main class="flex-1 max-w-full md:max-w-[calc(200%/3-24px)] xl:max-w-[calc(300%/4-24px)]">
          <div class="bg-white rounded">
            <slot></slot>
          </div>
        </main>
      </div>
    </div>

    <addendum />

    <global-alert />
  </div>
</template>

<script setup lang="ts">
import Navbar from '@/components/common/navbar.vue'
import Addendum from '@/components/common/addendum.vue'
import GlobalAlert from '@/components/common/global-alert.vue'
import LineBannerWithClient from '@/components/social/line-banner-with-client.vue'
import MemberRouteList from '@/components/member/member-route-list.vue'

import { useAppStore } from '@/store/app'
import { useRegionStore } from '@/store/region'

useSeoMeta({
  robots: 'noindex',
})

const app = useAppStore()
const regionStore = useRegionStore()

await useAsyncData('regionList', regionStore.fetchRegionList)

const isSideBarShow = computed(() => app.isDesktopScreen)
</script>

<style lang="scss" scoped>
#personal-info-container {
  min-height: var(--content-min-height);
}
</style>
